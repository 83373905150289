import type { BaseEntity } from '@indomita-website/map-polygons';
import { ENTITY_TYPE } from '@indomita-website/map-polygons/src/types';

import type { GeographyValue } from 'src/components/GeographySearch/types';
import type {
  formConfigType,
  FormFields,
  selectConfigType,
} from 'src/components/SearchFilters/config/formConfigTypes';

import type { GeographySearch } from 'src/libs/geography';

import type { Trans } from './i18nFormatters';
import { capitalize } from './string';

export const getWhere = (
  geography: GeographySearch | null,
  trans: Trans
): Nullable<string> => {
  if (geography?.searchType !== 'place') {
    if (geography?.value['indirizzo']) {
      const address = geography.value['indirizzo']
        .split('-')
        .map(capitalize)
        .join(' ');
      const cityLabel = geography.value['cityLabel'];

      return `${address}${cityLabel ? ` • ${cityLabel}` : ''}`;
      // Ricerca per via
    }

    return capitalize(trans('search_formatter_area_drawn')); // Area disegnata
  }

  if (
    geography.value[0].type === ENTITY_TYPE.province ||
    geography.value[0].type === ENTITY_TYPE.city
  ) {
    return geography.value[0].label; // TODO Check with product labels for these cases
  } else if (
    geography.value[0].type === ENTITY_TYPE.cityZone ||
    geography.value[0].type === ENTITY_TYPE.microzone
  ) {
    // We have multiple selection of macro/micro
    // First of all we need the city parent
    const parentCity = geography.value[0].parents.find(
      (entity) => entity.type === ENTITY_TYPE.city
    );

    if (parentCity) {
      // Returning the formatted label
      return `${parentCity.label} ${trans(
        'search_formatter_with_zone_selected',
        {
          count: geography.value.length,
          params: [geography.value.length],
        }
      )}`;
    }
  }

  // Unhandled case or metro search

  return null;
};

export const getRangeValues = (
  trans: Trans,
  minValue?: Nullable<string>,
  maxValue?: Nullable<string>
): Nullable<string> => {
  if (minValue && maxValue) {
    return `${minValue} - ${maxValue}`;
  }

  if (maxValue) {
    return trans('search_formatter_max', { params: [maxValue] });
  }

  if (minValue) {
    return trans('search_formatter_min', { params: [minValue] });
  }

  return null;
};

export const getRoomsValue = (
  trans: Trans,
  translations: {
    uniqueValue: string;
    bothValues: string;
    onlyMin: string;
    onlyMax: string;
  },
  minValue?: string,
  maxValue?: string
): Nullable<string> => {
  if (minValue && maxValue) {
    if (minValue === maxValue) {
      return capitalize(
        // i18n-extract-disable-next-line
        trans(translations.uniqueValue, { params: [minValue] })
      );
    }

    return capitalize(
      // i18n-extract-disable-next-line
      trans(translations.bothValues, {
        params: [minValue, maxValue],
      })
    );
  } else if (maxValue) {
    // i18n-extract-disable-next-line
    return trans(translations.onlyMax, {
      count: parseInt(maxValue, 10),
      params: [maxValue],
    });
  } else if (minValue) {
    // i18n-extract-disable-next-line
    return trans(translations.onlyMax, {
      count: parseInt(minValue, 10),
      params: [minValue],
    });
  }

  return null;
};

export const getSelectValue = (
  field: FormFields,
  value: string,
  formConfig: formConfigType
): Optional<string> => {
  const valueConfig = formConfig.find(
    (config) => config.field === field
  ) as selectConfigType;

  return valueConfig.options.find((option) => option.id === value)?.value;
};

export const getMultiselectValues = (
  mapper: { [value: string]: string },
  featureName?: string,
  values?: string[]
) => {
  if (values) {
    const valuesCopy = [...values];

    valuesCopy.sort();
    const translationKeys = valuesCopy
      .map((value) => mapper[value])
      .filter(Boolean);

    return featureName
      ? `${capitalize(featureName)}: ${translationKeys.join(', ')}`
      : translationKeys.join(', ');
  }

  return null;
};

export const getMetroValue = (
  values: GeographyValue[],
  trans: Trans
): string => {
  // Removing duplicated stations
  values = filterMetroWithSameLabel(values);

  if (values.length === 1) {
    return formatMetroBadge(values[0], trans);
  }

  const { label, description } = computeMetroEntryLabel(values, trans);

  return `${label} • ${description}`;
};

const filterMetroWithSameLabel = (
  entries: GeographyValue[]
): GeographyValue[] => {
  const filteredEntries: GeographyValue[] = [];

  entries.forEach((entry) => {
    if (entry.type === ENTITY_TYPE.metro) {
      const duplicatedEntry = entries.find((e) => {
        let res;

        if (e.type === ENTITY_TYPE.metroLine) {
          // For every metro line selected, I need to check children
          res = Boolean(
            e.children?.find((child) => child.label === entry.label)
          );
        } else {
          // Checking if I already have a duplicate for this entry
          res = filteredEntries.find((e) => e.label === entry.label);
        }

        return res;
      });

      if (!duplicatedEntry) {
        filteredEntries.push(entry);
      }
    } else {
      // Metro lines can be added without problems
      filteredEntries.push(entry);
    }
  });

  return filteredEntries;
};

const formatMetroBadge = (entity: BaseEntity, trans: Trans): string => {
  if (entity.type === ENTITY_TYPE.metro) {
    return `${trans('lbl_metro')} ${entity.label}`;
  }

  return `${entity.parents[0].label} • ${entity.label}`;
};

const computeMetroEntryLabel = (
  entries: BaseEntity[],
  trans: Trans
): { label: string; description: string } => {
  const selection: GeographyValue = entries[0];
  const isMultiSelection = entries.length > 1;
  const withParents = selection.parents.length > 0;

  const metroLines =
    selection.type === ENTITY_TYPE.metro
      ? [
          selection.parents.find(
            (parent) => parent.type === ENTITY_TYPE.metroLine
          )?.label,
        ]
      : null;

  let label =
    selection.type === ENTITY_TYPE.metro
      ? `${trans('lbl_metro')} ${selection.label} - ${metroLines?.join(', ')}`
      : selection.label;

  const parentCity = selection.parents.find(
    (parent) => parent.type === ENTITY_TYPE.city
  );

  // multi selection with zones
  if (isMultiSelection && parentCity) {
    label = parentCity.label;
  }

  let description = '';

  if (isMultiSelection && withParents) {
    if (
      selection.type === ENTITY_TYPE.metro ||
      selection.type === ENTITY_TYPE.metroLine
    ) {
      description = computeMetroDescription(entries, trans);
    }
  } else {
    let parent = '';

    if (withParents) {
      if (parentCity && parentCity.label) {
        parent = parentCity.label;
      } else {
        parent = selection.parents[0].label;
      }
    }

    // i18n-extract-mark-context-next-line ["1", "2", "3", "4", "6", "8"]
    description = trans('geo_entity_type', {
      params: [selection.label, parent],
      context: `${selection.type}`,
    });
  }

  return {
    label,
    description,
  };
};

/**
 * Returns the description for a list of metros and metro lines
 * e.g 2 linee metro or 25 stazioni metro
 * @param entries
 * @param trans
 */
const computeMetroDescription = (
  entries: GeographyValue[],
  trans: Trans
): string => {
  switch (true) {
    case entries.every((entry) => entry.type === ENTITY_TYPE.metroLine):
      return trans('lbl_metro_lines', { params: [entries.length] });
    default:
      return trans('lbl_metro_stations', {
        params: [
          entries.reduce(
            (acc, entry) =>
              entry.type === ENTITY_TYPE.metroLine
                ? acc + (entry.children ? entry.children.length : 0)
                : acc + 1,
            0
          ),
        ],
      });
  }
};
